export type FirebaseProjectsList = {
  [key: string]: {
    apiKey: string
    appId: string
    authDomain: string
    measurementId?: string
    messagingSenderId: string
    projectId: string
    storageBucket: string
  }
}

const FIREBASE_PROJECTS: FirebaseProjectsList = {
  beraCi: {
    apiKey: 'AIzaSyDUWG9oLW_OHczTPZp8iWOodcB0qJ2P-DQ',
    appId: '1:703470123443:web:8ac3fb43e0b73f7ef333c6',
    authDomain: 'beraci.firebaseapp.com',
    messagingSenderId: '703470123443',
    projectId: 'beraci',
    storageBucket: 'beraci.appspot.com',
  },
  beraLocal: {
    apiKey: 'AIzaSyBQxgDAcKe3KDeQUG5UJoH1Lqk7oKQ0amA',
    appId: '1:530793812344:web:6809ea18d68fc466e0b88d',
    authDomain: 'bera-local.firebaseapp.com',
    measurementId: 'G-B23BREKSY7',
    messagingSenderId: '530793812344',
    projectId: 'bera-local',
    storageBucket: 'bera-local.appspot.com',
  },
  beraStaging: {
    apiKey: 'AIzaSyB-zOF5U6-dCmvd5ZEy7lZxFzy_UirxPuk',
    appId: '1:338000665888:web:46d4be06e2d70687728b45',
    authDomain: 'bera-staging-59243.firebaseapp.com',
    measurementId: 'G-YE46G8WMZK',
    messagingSenderId: '338000665888',
    projectId: 'bera-staging-59243',
    storageBucket: 'bera-staging-59243.appspot.com',
  },
  beraTestnet: {
    apiKey: 'AIzaSyDbSjwen0e1zfVT96Nsag5jXF1l6NDoN70',
    appId: '1:912867646681:web:c7f37fcc55d24bcaca32c8',
    authDomain: 'bera-testnet.firebaseapp.com',
    measurementId: 'G-8Z2L2LHEHJ',
    messagingSenderId: '912867646681',
    projectId: 'bera-testnet',
    storageBucket: 'bera-testnet.appspot.com',
  },
  pavelLocal: {
    apiKey: 'AIzaSyB5UudG_WOmhgLGqbf1bxxfbLATGpQECHg',
    appId: '1:466828058928:web:7005f22d35092c900b68f5',
    authDomain: 'moonblast-pavel-test.firebaseapp.com',
    messagingSenderId: '466828058928',
    projectId: 'moonblast-pavel-test',
    storageBucket: 'moonblast-pavel-test.appspot.com',
  },
  pavelMbTest2: {
    apiKey: 'AIzaSyB7SRMGxXUo-E8LOaZWEJkenSbTTKDjJUY',
    appId: '1:966258455396:web:a91d8766f1ebe6dba24071',
    authDomain: 'pavel-mb-test-2.firebaseapp.com',
    measurementId: 'G-0Q21M6974L',
    messagingSenderId: '966258455396',
    projectId: 'pavel-mb-test-2',
    storageBucket: 'pavel-mb-test-2.appspot.com',
  },
}

export default FIREBASE_PROJECTS
