import {
  FT_AUTOTRANSFER_ENABLED,
  FT_BROWSER_WALLET_ENABLED,
} from '@/config/feature-toggles'
import {
  chains,
  ethereumEnv,
  firebaseConfig,
  firebaseEnv,
  graphqlUrls,
  testWalletPrivateKey,
} from '@/config/provider-config'
import { getUnixTSFromDate } from '@p2p-solutions-fzco/launchpad-data-service'

export function checkConfig() {
  console.log(getUnixTSFromDate(new Date()))
  console.log(
    'VITE_APP_ETHEREUM_ENV',
    process.env.VITE_APP_ETHEREUM_ENV,
    ethereumEnv,
    chains[0].label,
  )
  console.log(
    'VITE_APP_FIREBASE_ENV',
    process.env.VITE_APP_FIREBASE_ENV,
    firebaseEnv,
    firebaseConfig.projectId,
  )
  console.log(
    'VITE_APP_ADMIN_SERVICE_URL',
    process.env.VITE_APP_ADMIN_SERVICE_URL,
  )
  console.log(
    'VITE_APP_BROWSER_WALLET_ENABLED',
    process.env.VITE_APP_BROWSER_WALLET_ENABLED,
    FT_BROWSER_WALLET_ENABLED,
  )
  console.log(
    'VITE_APP_AUTOTRANSFER_ENABLED',
    process.env.VITE_APP_AUTOTRANSFER_ENABLED,
    FT_AUTOTRANSFER_ENABLED,
  )
  if (FT_BROWSER_WALLET_ENABLED === false) {
    console.log('--------- WARNING: Test Wallet is enabled ---------')
    console.log(
      'VITE_APP_TEST_WALLET_PRIVATE_KEY',
      process.env.VITE_APP_TEST_WALLET_PRIVATE_KEY,
      testWalletPrivateKey,
    )
  }
  console.log('VITE_APP_GRAPH_ENV', process.env.VITE_APP_GRAPH_ENV, graphqlUrls)
  return true
}
