// import CoingeckoIcon from '@/assets/svg/coingecko.svg?react'
// import CoinMarketCapIcon from '@/assets/svg/coinmarketcap.svg?react'
// import DexScreenerIcon from '@/assets/svg/dexscreener.svg?react'
// import DexToolsIcon from '@/assets/svg/dextools.svg?react'
import FrameSmallBottomLeft from '@/assets/svg/frame-small-bottom-left.svg?react'
import logo from '@/assets/svg/logo.svg'
// import MediumIcon from '@/assets/svg/medium.svg?react'
import TelegramIcon from '@/assets/svg/telegram.svg?react'
import XTwitterIcon from '@/assets/svg/X.svg?react'
import { MobileMenu } from '@/components/mobile-menu'
import { FOOTER_LINKS, PAGES } from '@/config/links'
import { cn } from '@/utils/class-merge'
import { LinkButton } from '@p2p-solutions-fzco/launchpad-ui-library'
import { ReactNode, Suspense } from 'react'
import { Link, ScrollRestoration, useLocation } from 'react-router-dom'

function Layout({ children }: { children: ReactNode }) {
  const location = useLocation()
  return (
    <>
      <ScrollRestoration />
      <header className="relative z-50">
        <div className="container  mx-auto flex h-28 items-center justify-between p-5">
          <Link to="/">
            <img
              alt="Beralaunch Logo"
              className="w-40 md:w-48"
              height={56}
              src={logo}
              width={207}
            />
          </Link>
          <nav className="hidden gap-7 font-bold xl:flex">
            {PAGES.map(({ link, title }) => {
              const isActive = location.pathname === link
              return (
                <Link
                  className={cn(
                    'border-transparent border-b-2 py-2',
                    isActive && 'border-primary text-primary',
                  )}
                  to={link}
                >
                  {title}
                </Link>
              )
            })}
          </nav>
          <div className="flex">
            <MobileMenu className="mr-3 sm:mr-3 xl:hidden" />
            <LinkButton
              className="text-nowrap border border-grey-100 font-instrument-sans font-medium"
              href="https://testnet.beralaunch.com"
              size="large"
              target="_blank"
              variation="tertiary"
            >
              Go To App
            </LinkButton>
          </div>
        </div>
      </header>
      <main className="-my-28 mb-0 min-h-screen overflow-hidden">
        <Suspense>{children}</Suspense>
      </main>

      <footer className="relative border-t border-primary bg-dark">
        <FrameSmallBottomLeft className="absolute bottom-0 left-0" />

        <div className="container mx-auto p-5">
          <div className="my-7 justify-between lg:flex">
            <img alt="Beralaunch Logo" height={56} src={logo} width={207} />
            <nav className="mt-7 flex gap-7">
              {FOOTER_LINKS.map(({ link, title }) => (
                <Link to={link}>{title}</Link>
              ))}
            </nav>
          </div>
          <div className="mb-7 items-center justify-between lg:flex ">
            <div className="mb-7 flex gap-7">
              <Link target="_blank" to="https://x.com/BeralaunchCom">
                <XTwitterIcon className="size-6 fill-white" />
              </Link>
              <Link target="_blank" to="https://t.me/BeraLaunch">
                <TelegramIcon className="size-6 fill-white" />
              </Link>
              {/* <MediumIcon className="size-6 fill-white" />
            <CoinMarketCapIcon className="size-6 fill-white" />
            <CoingeckoIcon className="size-6 fill-white" />
            <DexToolsIcon className="size-6 fill-white" />
            <DexScreenerIcon className="size-6 fill-white" /> */}
            </div>
            <nav className="mb-7 flex gap-7 text-sm">
              <Link to="privacy-policy">Privacy Policy</Link>
              <Link to="terms">Terms & Conditions</Link>
            </nav>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Layout
