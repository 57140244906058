import { lazy } from 'react'

export const Home = lazy(() => import('@/pages/home/home'))
export const Projects = lazy(() => import('@/pages/projects/projects'))
export const Ventures = lazy(() => import('@/pages/ventures/ventures'))
export const News = lazy(() => import('@/pages/news/news'))
export const PrivacyPolicy = lazy(() => import('@/pages/privacy-policy'))
export const TermsAndConditions = lazy(() => import('@/pages/terms'))
export const Incubator = lazy(() => import('@/pages/incubator/incubator'))
export const Token = lazy(() => import('@/pages/token/token'))
export const Article = lazy(() => import('@/pages/news/article'))

export default Home
