import CrossIcon from '@/assets/svg/cross.svg?react'
import MenuIcon from '@/assets/svg/menu.svg?react'
import { PAGES } from '@/config/links'
import { cn } from '@/utils/class-merge'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export function MobileMenu({ className }: { className?: string }) {
  const [isOpen, setIsOpen] = useState(false)
  const toggleMenu = () => setIsOpen(!isOpen)

  return (
    <div className={cn('flex', className)}>
      <button onClick={toggleMenu}>
        {isOpen ? (
          <CrossIcon className="size-10 fill-white" />
        ) : (
          <MenuIcon className="size-10 fill-white" />
        )}
      </button>
      <div
        className={cn(
          'bg-dark-blue absolute left-0 top-0 -z-10 w-full',
          isOpen && 'block',
          !isOpen && 'hidden',
        )}
      >
        <nav className="container mx-auto">
          <ul className="mx-2 mt-24">
            {PAGES.map(({ link, title }) => (
              <li className="mb-3 block p-3" key={title}>
                <Link
                  className="font-medium text-white hover:opacity-90 active:opacity-80"
                  onClick={toggleMenu}
                  to={link}
                >
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}
