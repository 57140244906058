type GraphQLUrls = {
  [key: string]: Graph[]
}

type Graph = {
  chainId: string
  url: string
}

export const GRAPHQL_URLS: GraphQLUrls = {
  local: [
    {
      chainId: '0x138d4', // berachain
      url: 'http://127.0.0.1:4000',
    },
  ],
  staging: [
    {
      chainId: '0x138d4', // berachain
      url: 'https://api.goldsky.com/api/public/project_clw8xy8xrowx201tb3xiycy8i/subgraphs/beralaunch-bera-staging/latest/gn',
    },
  ],
  testnet: [
    {
      chainId: '0x138d4', // berachain
      url: 'https://api.goldsky.com/api/public/project_clw8xy8xrowx201tb3xiycy8i/subgraphs/beralaunch-bera-testnet/latest/gn',
    },
  ],
}

export const GRAPHQL_COMPETITION_URLS: GraphQLUrls = {
  local: [
    {
      chainId: '0xa0c71fd', // testnet
      url: 'http://127.0.0.1:4000',
    },
  ],
  staging: [
    {
      chainId: '0xa0c71fd', // testnet
      url: 'https://api.studio.thegraph.com/query/66912/mb-competitions-blast-testnet/version/latest',
    },
  ],
  testnet: [
    {
      chainId: '0xa0c71fd', // testnet
      url: 'https://api.studio.thegraph.com/query/66912/mb-competitions-blast-testnet/version/latest',
    },
  ],
}
