export const FT_BROWSER_WALLET_ENABLED =
  process.env.VITE_APP_BROWSER_WALLET_ENABLED === undefined
    ? true // true if undefined
    : process.env.VITE_APP_BROWSER_WALLET_ENABLED === 'true' // otherwise match boolean value to text

export const FT_JOIN_DEAL_WITH_SIGNATURE_ENABLED =
  process.env.VITE_APP_JOIN_DEAL_WITH_SIGNATURE_ENABLED === undefined
    ? true // true if undefined
    : process.env.VITE_APP_JOIN_DEAL_WITH_SIGNATURE_ENABLED === 'true'

export const FT_AUTOTRANSFER_ENABLED =
  process.env.VITE_APP_AUTOTRANSFER_ENABLED === undefined
    ? false // false if undefined
    : process.env.VITE_APP_AUTOTRANSFER_ENABLED === 'true'

export const FT_INVITE_CODE_WITH_SIGNATURE_ENABLED =
  process.env.VITE_APP_INVITE_CODE_WITH_SIGNATURE_ENABLED === undefined
    ? true // true if undefined
    : process.env.VITE_APP_INVITE_CODE_WITH_SIGNATURE_ENABLED === 'true'

export const FT_CHECK_CONFIG =
  process.env.VITE_APP_CHECK_CONFIG === undefined
    ? false // false if undefined
    : process.env.VITE_APP_CHECK_CONFIG === 'true'

export const FT_FAUCET_ENABLED =
  process.env.VITE_APP_FT_FAUCET_ENABLED === undefined
    ? false // false if undefined
    : process.env.VITE_APP_FT_FAUCET_ENABLED === 'true'

export const FT_KYC_ENABLED =
  process.env.VITE_APP_FT_KYC_ENABLED === undefined
    ? true // true if undefined
    : process.env.VITE_APP_FT_KYC_ENABLED === 'true'

export const toggles = {
  FT_AUTOTRANSFER_ENABLED,
  FT_BROWSER_WALLET_ENABLED,
  FT_CHECK_CONFIG,
  FT_FAUCET_ENABLED,
  FT_INVITE_CODE_WITH_SIGNATURE_ENABLED,
  FT_JOIN_DEAL_WITH_SIGNATURE_ENABLED,
  FT_KYC_ENABLED,
}
