import ErrorPage from '@/pages/error'
import NotFoundPage from '@/pages/not-found'
import { createBrowserRouter, Outlet } from 'react-router-dom'

import Layout from './layout'
import {
  Article,
  Home,
  Incubator,
  News,
  PrivacyPolicy,
  Projects,
  TermsAndConditions,
  Token,
  Ventures,
} from './routes'

const router = createBrowserRouter([
  {
    children: [
      { element: <Home />, index: true },
      { element: <Projects />, path: 'projects' },
      { element: <Ventures />, path: 'beralaunch-ventures' },
      { element: <News />, path: 'news' },
      { element: <Article />, path: 'news/:slug' },
      { element: <Incubator />, path: 'incubator' },
      { element: <Token />, path: 'token' },
      { element: <PrivacyPolicy />, path: 'privacy-policy' },
      { element: <TermsAndConditions />, path: 'terms' },
      { element: <NotFoundPage />, path: '*' },
    ],
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    errorElement: (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
    path: '/',
  },
])

export default router
