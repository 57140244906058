function NotFound() {
  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="text-center">
        <h1 className="text-primary">404</h1>
        <p>Page not found</p>
      </div>
    </div>
  )
}

export default NotFound
