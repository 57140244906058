export const PAGES = [
  { link: '/#products', title: 'Products' },
  { link: '/projects', title: 'Projects' },
  { link: '/beralaunch-ventures', title: 'BeraLaunch Ventures' },
  { link: '/news', title: 'News' },
  { link: '/incubator', title: 'Incubator' },
  { link: '/token', title: 'Token' },
]

export const FOOTER_LINKS = [
  { link: '/projects', title: 'Projects' },
  { link: '/token', title: 'Token' },
  { link: '/incubator', title: 'Incubator' },
  { link: 'https://docs.beralaunch.com', title: 'Docs' },
]

export const FUNDRAISE_APPLY_LINK = 'https://forms.gle/RSdtmtnNXxbsB14e7'

export const ACCELERATION_APPLY_LINK = 'https://forms.gle/2u4za79So5jreTAx5'
