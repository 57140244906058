export type Chain = {
  explorerURL?: string
  id: string
  image: string
  label: string
  rpcUrl: string
  token: string
}

type EthereumChainsList = {
  [key: string]: Chain[]
}

const ETHEREUM_CHAINS: EthereumChainsList = {
  local: [
    {
      explorerURL: 'https://bartio.beratrail.io',
      id: '0x138d4',
      image: 'https://s2.coinmarketcap.com/static/img/coins/128x128/24647.png',
      label: 'Berachain Local (Artio)',
      rpcUrl: 'http://127.0.0.1:8545',
      token: 'BERA',
    },
  ],
  testnet: [
    {
      explorerURL: 'https://bartio.beratrail.io',
      id: '0x138d4',
      image: 'https://s2.coinmarketcap.com/static/img/coins/128x128/24647.png',
      label: 'Berachain Artio',
      rpcUrl: 'https://bartio.rpc.berachain.com',
      token: 'BERA',
    },
  ],
}

export default ETHEREUM_CHAINS
