import ETHEREUM_CHAINS from './ethereum-chains'
import { toggles } from './feature-toggles'
import FIREBASE_PROJECTS from './firebase-projects'
import { GRAPHQL_COMPETITION_URLS, GRAPHQL_URLS } from './graphql-urls'

export const graphEnv = process.env.VITE_APP_GRAPH_ENV || 'testnet'
export const graphqlUrls = GRAPHQL_URLS[graphEnv]
export const graphqlCompetitionUrls = GRAPHQL_COMPETITION_URLS[graphEnv]

export const ethereumEnv = process.env.VITE_APP_ETHEREUM_ENV || 'testnet'
export const chains = ETHEREUM_CHAINS[ethereumEnv]

export const firebaseEnv = process.env.VITE_APP_FIREBASE_ENV || 'beraStaging'
export const firebaseConfig = FIREBASE_PROJECTS[firebaseEnv]

export const testWalletPrivateKey =
  process.env.VITE_APP_TEST_WALLET_PRIVATE_KEY || ''

export const adminServiceUrl = process.env.VITE_APP_ADMIN_SERVICE_URL || ''

export const baseUrl =
  process.env.VITE_APP_PAGE_DOMAIN || 'https://app.beralaunch.com'

export const appConfig = {
  adminServiceUrl,
  baseUrl,
  chains,
  firebaseConfig,
  graphEnv,
  graphqlUrls,
  testWalletPrivateKey,
  toggles,
}
