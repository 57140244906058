import { FT_CHECK_CONFIG, toggles } from '@/config/feature-toggles'
import { appConfig, firebaseConfig } from '@/config/provider-config'
import {
  AppConfigProvider,
  FirebaseProvider,
  GraphqlProvider,
  PageDataProvider,
} from '@p2p-solutions-fzco/launchpad-ui-library'
import { RouterProvider } from 'react-router-dom'
import { SWRConfig } from 'swr'

import './index.css'
import { checkConfig } from './init'
import routes from './router'

function App() {
  console.log('CURRENT TIME: ', new Date())

  if (FT_CHECK_CONFIG) {
    checkConfig()
  }

  return (
    <AppConfigProvider config={appConfig} toggles={toggles}>
      <FirebaseProvider config={firebaseConfig}>
        <GraphqlProvider>
          <SWRConfig
            value={{
              dedupingInterval: 2000,
              focusThrottleInterval: 10000,
              keepPreviousData: true,
              refreshInterval: 0,
              revalidateIfStale: false,
              revalidateOnFocus: true,
              revalidateOnMount: true,
              shouldRetryOnError: false,
            }}
          >
            <PageDataProvider>
              <RouterProvider router={routes} />
            </PageDataProvider>
          </SWRConfig>
        </GraphqlProvider>
      </FirebaseProvider>
    </AppConfigProvider>
  )
}

export default App
